document.addEventListener('turbolinks:load', () => {
  const radioButtons = document.querySelectorAll('[data-donation-radio]');

  radioButtons.forEach((button) => {
    button.addEventListener('change', (evt) => {
      const amountInput = document.querySelector('[data-donation-amount-input]');
      amountInput.value = evt.target.value;
    });
  });
});
